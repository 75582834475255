import "./App.css";
import ChiTiet from "./chitiet/chitiet";

function App() {
  return (
    <div className="App">
      <ChiTiet />
    </div>
  );
}

export default App;
